
.article {
    display: flex;
    border-bottom: 1px solid #D1D1D1;
    padding-bottom: 2rem;

    .row-sidebar {
        padding-top: 3rem;
        width: 300px;
        @media only screen and (max-width: 840px) {
            display: none;
        }
    }
    .row-content {
        margin: 0rem 2rem;
        padding: 0.2rem;
        display: flex;
        flex-direction: column;
        max-width: 544px;

        @media only screen and (max-width: 840px) {
            margin: auto;
        }
        
        a.title {
            color: black;
            text-decoration: none;

            :hover {
                color: #429e87;
            }

            .date {
                    text-align: center;
                    width: 100%;
                    font-family: "__domaineDisplay_1ffa62", "__domaineDisplay_Fallback_1ffa62";
                    margin: 2rem 0rem;
            }
        }

        .text {
            .art {
                height: 12rem;
                width: 15rem;
                float: left;
                margin-right: 1rem;
                position: relative;
                overflow: hidden;
                display: flex;
                
                &:hover img {
                    transform: scale(1.1);
                }

                @media only screen and (max-width: 840px) {
                    width: 100%;
                    height: 20rem;
                }
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.5s all ease-in-out;

                    
                }

            
                span {
                    position: absolute;
                    bottom: 0px;
                    right: 5px;
                    color: white;
                    text-shadow: 5px 0px 5px black;

                }
            }
        }
    }

    
}