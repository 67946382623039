.store {
    margin: 1rem 0 2rem 0;

    .wrapper {
        max-width: 544px;
        margin: 0 auto;

        .product-filter {
            display: flex;
            margin-bottom: 0.5rem;

            .filter-image {
                width: 5.6rem;
                height: 5.6rem;
            }
            .product-options {
                flex-grow: 1;

                > :first-child {
                    margin-bottom: 0.5rem;
                }
            }
        }

        .mode-filter {
            display: flex;
            margin-bottom: 0.5rem;

            > * {
                ~ * {
                    margin-left: 0.5rem;
                }

                height: 2.5rem;
                flex-grow: 1;
                text-align: center;
                border: 1px solid hsl(0, 0%, 80%);
                cursor: pointer;
                background-color: teal;
                color: white;
                opacity: 0.6;
                transition: 0.3s opacity;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    margin-left: 0.5rem;
                }

                &:hover,
                &.is-active {
                    opacity: 1;
                }
            }
        }

        .art-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .picture {
                display: flex;

                width: calc(100% / 2);
            }

            .affiliate {
                width: 100%;
            }
        }
    }

    .modal {
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 2000;

        .content {
            max-width: 500px;
            margin: 0 auto;

            .body {
                margin-top: 30vh;
                background-color: white;
                border-radius: 4px;

                .inner-body {
                    padding: 1rem;

                    .message {
                        text-align: center;

                        margin-bottom: 2rem;

                        svg {
                            margin-left: 1rem;
                        }
                    }

                    .button {
                        margin: auto;
                        width: 10rem;
                        background-color: teal;
                        color: white;
                        height: 2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        opacity: 0.8;
                        transition: opacity 0.2s;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
