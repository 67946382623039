
.banner {
    margin: auto 0;
    margin-top: 1rem;
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;

    .art {
        display: flex;
        height: 15rem;
        width: calc(100% / 4);
        overflow: hidden;

        @media only screen and (max-width: 840px) {
            height: 7rem;
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        right: 1rem;
        z-index: 2;
        font-family: "__domaineDisplay_1ffa62", "__domaineDisplay_Fallback_1ffa62";
        color: white;
        text-shadow: 5px 0px 5px black;
        font-size: 2rem;
        font-weight: bold;

        @media only screen and (max-width: 840px) {
            bottom: unset;
            right: unset;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}