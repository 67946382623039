.header {
    background: linear-gradient(
        90deg,
        rgba(197, 242, 231, 1) 0%,
        rgba(255, 255, 255, 1) 35%,
        rgba(255, 255, 255, 1) 100%
    );

    .content-wrapper {
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid #ccc;
        padding: 0 20px;

        .content {
            padding: 10px 0px;
            max-width: 1270px;
            margin: 0 auto;
            display: flex;
            height: 64px;

            .banner {
                height: 45px;
            }
        }
    }

    .subheader-wrapper {
        padding: 0 20px;

        .subheader {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            max-width: 1270px;
            margin: 0 auto;

            text-decoration: none;
            color: #000;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.1em;
            font-family: Arial, sans-serif;
        }
    }
}
