.nav {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    flex-grow: 1;

    .menu-item {
        padding: 0 1rem;
        text-decoration: none;
        color: #000;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: Arial, sans-serif;
        font-weight: 600;

        &:hover {
            color: #429e87;
        }
    }

    @media only screen and (max-width: 1024px) {
        display: none;
    }
}
