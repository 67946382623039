         
.art {
    width: 100%;
    position: relative;
    overflow: hidden;

    &:hover {
        z-index: 1;

        img {
            transform: scale(1.1);
            
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s all ease-in-out;
    }

    span {
        position: absolute;
        bottom: 0px;
        right: 5px;
        color: white;
        text-shadow: 5px 0px 5px black;

    }
}
