.row-social {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 7rem;

    @media only screen and (max-width: 840px) {
        display: none;
    }

    a {
        color: black;
        overflow: hidden;
        
        svg {
            transition: 0.2s all ease-in-out;
        }

        &:hover svg {
            transform: scale(1.1);
        }


        :hover {
            color: #429e87;
        }

        .social-button {
            border: 2px solid black;
            text-align: center;
            border-radius: 100%;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
        }
    }
}
