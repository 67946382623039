.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    border: 1px solid white;

    .art {
        flex-grow: 1;
        width: 100%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        background-color: #c1c1c1;

        &:hover {
            z-index: 1;

            img {
                transform: scale(1.1);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s all ease-in-out;
        }

        span {
            position: absolute;
            bottom: 0px;
            right: 5px;
            color: white;
            text-shadow: 5px 0px 5px black;
        }
    }
    .order {
        color: red;
        top: 50%;
        width: 100%;
        text-align: center;
        color: white;
        background-color: teal;
        opacity: 0.8;
        height: 3rem;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.2s opacity;

        &:hover {
            opacity: 1;
        }
    }
}
