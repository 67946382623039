.aiart {
    margin: 2rem 0;

    .art-list {
        display: flex;
        padding: 0.2rem;
        margin: 0 auto;
        max-width: 544px;
        flex-wrap: wrap;

        .row {
            width: 100%;
        }
    }
    
}