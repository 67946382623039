.page {
       background-color: white;

       .content {
              max-width: 1270px;
              margin: auto;
              min-height: calc(100vh - 150px);

              @media only screen and (max-width: 840px) {
                     padding: 0 1rem;
              }

              .loading {
                     margin: auto;
                     width: 2rem;
                     text-align: center;
              }
       }

      

       .footer {
              display: flex;
              justify-content: center;
              color: #AAA;
              font-family: "__domaineDisplay_1ffa62", "__domaineDisplay_Fallback_1ffa62";
       }
}