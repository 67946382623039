.nav {
    height: 0px;
    display: none;

    .menu-item {
        text-decoration: none;
        color: black;
        width: 100%;
        margin: 0.5rem 0rem;
        text-align: center;

        &:hover {
            color: #429e87;
        }

        &.bottom {
            position: absolute;
            bottom: 0.5rem;
            left: 0rem;
        }
    }

    @media only screen and (max-width: 1024px) {
        display: block;
    }
}
