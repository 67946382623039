.picker {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    .select {
        text-align: right;
        min-width: 10rem;
    }
}
